
import { Message } from 'view-design'
export default {
  setStateVal (state, payload) {
    try {
      for (let key in payload) {
        state[key] = payload[key]
      }
    } catch (err) {
      Message.warning('使用有误，请检查')
    }
  }
}
