// 全局状态模块
import wj from '@/plugins/wjtools'
import menuList from '@/router/my-center'
import mutations from './mutations'
import actions from './actions'

const state = {
  menuList, // 个人中心菜单列表
  serverTime: '', // 服务器时间
  wxConfigState: '', // 小程序配置状态
  isLogin: wj.isLogin(),
  username: wj.cookie('username'),
  fbaData: [],
  fbaFilterData: [],
  countryData: [],
  unFillOrder: {},
  showOrder: false,
  showWaitPay: false,
  waitPayOrder: {},
  homeTxt: '首页',
  userStatus: 'check',
  tipsType: 'reg', // reg 注册后提示 check 审核中提示
  showTips: false, // 审核中，操作显示提示框
  showActive: sessionStorage.getItem('showActive') !== '1', // 1为已经显示过
  showLogin: false, // 显示登录弹框
  billInfo: {}, // 用户账期信息
  showGlobal: false,
  VIPUsers: ['13686894270', '13826338101', '17602115301'],
  loginType: 'login',
  showInviteRedpackage: false, // 拉新活动显示红包弹框
  showActiveEight: false, // 817活动首页弹窗
  // Im 相关
  showIframe: false, // 是否展示Iframe
  shopId: 0, // shopId
  shopIdName: '', // 打开IM时携带线路名称
  order: '', // 打开IM时携带线路名称或下单咨询服务商区别
  imOrderId: '', // 打开IM时携带订单id
  channelId: '', // 打开IM时携带线路id
  channelLibId: '', // 打开IM时携带方案id
  cityId: '', // 打开IM时携带cityId
  countryCode: '', // 打开IM时countryCode
  warehouseAddress: '', // 打开IM时warehouseAddress
  warehouseType: '', // 打开IM时warehouseType
  totalWeight: '', // 打开IM时totalWeight
  totalVolume: '', // 打开IM时totalVolume
  origin: '', // 打开IM时origin
  storeId: '', // 打开IM时storeId(船期信息id)
  storeNum: '', // 打开IM时预估方数
  token: '',
  imOrderType: 1, // 1-fba 2-小包 3-整柜

  homeScrollTop: 0, // 首页滚动距离
  hasNewMessage: 0, // 右侧我的消息 有新的消息
  hasNewMessageIM: 0, // 右侧我的消息 有新的消息(IM消息条数)
  // 消息中心参数
  notifyList: [], // 通知列表数据
  notifyDetailList: [], // 通知的详情列表数据
  noReadCount: 0, // 左侧的菜单栏的未读总数
  showAccount: false, // 显示账号弹窗提示
  showAccountList: [],
  showBillTips: false, // 账期提示弹框
  paymentMethodVisible: false, // 账期配置
  verifyInfo: {}, // 认证信息
  showVerifyTips: false,
  isOverNum: false, // 判断是否下了10单
  showPlatformTip: false, // 平台公告弹框
  canPlaceOrder: false, // 是否可以下单
  onlogin: null, // 登录成功回调函数
  dialogFormVisible: false, // 精准报价的弹框
  isPrecisePrice: false, // 是否精准算价
  preciseObj: {}, // 精准报价数据
  formParams: {}, // 搜索框的值
  searchStatus: false,
  isSpace: false,
  showNewCustomerCoupon: false,
  pageViewTimeCount: 0, // 页面曝光时长
  containerPort: {}, // 整柜-启运港和目的港基础数据
  smallBagCountry: [], // 国际小包目的国家
  cityList: [], // 全网询价起运城市
  countryList: [] // 全网询价目的国家
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
