// import wj from '@/plugins/wjtools'

export default {
  setToken (state, payload) {
    state.token = payload
  },

  setUserInfo (state, payload = {}) {
    state.userInfo = payload
    localStorage.setItem('userInfo', JSON.stringify(payload))
  },

  setUser (state, payload) {
    state.username = payload
  },

  setUserLocation (state, data) {
    state.userLocation = data
  },

  set520ActiveStatus (state, data) {
    state.activeStatusOf520 = data
  },

  set618ActiveStatus (state, data) {
    state.activeStatusOf618 = data
    localStorage.setItem('activeStatusOf618', JSON.stringify(data))
  },

  // ?????????
  setUserGuideInfo(state, guideInfo) {
    state.userGuideInfo = guideInfo
  },
  setNickName(state, data) {
    state.nickName = data
  }
}
