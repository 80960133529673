import Vue from 'vue'
import {
  VXETable,
  Icon,
  Header,
  Column,
  Table,
  Edit,
  Input,
  Select,
  Validator,
  Tooltip,
  Modal,
  Toolbar,
  Button
} from 'vxe-table'
import XEUtils from 'xe-utils'
import zhLocale from 'vxe-table/lib/locale/lang/zh-CN'

Vue.use(Button)
Vue.use(Toolbar)
Vue.use(Modal)
Vue.use(Tooltip)
Vue.use(Validator)
Vue.use(Select)
Vue.use(Input)
Vue.use(Edit)
Vue.use(Icon)
Vue.use(Header)
Vue.use(Column)
Vue.use(Table)

// 按需加载的方式默认是不带国际化的，需要自行导入
VXETable.setup({
  zIndex: 9999999,
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhLocale, key), args)
})
