// 全局状态模块
import mutations from './mutations'
import actions from './actions'

const state = {
  channelsSlideList: [], // 方案对比列表-侧边栏
  schemeSlideList: [], // 线路对比列表-侧边栏
  channelsList: [], // 方案对比列表
  schemeList: [], // 线路对比列表
  index: null, // 工具栏激活项索引
  visible: true, // 工具栏展开
  contentVisible: false, // 工具栏右侧展开
  historyTabIndex: 1, // 搜索历史-tab索引
  compareTabIndex: 1, // PK-tab索引
  favoritesTabIndex: 1, // 收藏夹-tab索引
  inquiryTabIndex: 1 // 全网询价-tab索引
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
