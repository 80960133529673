import Vue from 'vue'
import Router from 'vue-router'
import { LoadingBar } from 'view-design'
import stat from 'vue-stat'
import store from '@/store'
import wj from '@/plugins/wjtools'
import istat from '@/plugins/istat'
import home from './home'
import events from './events'
import myCenter from './my-center'
import common from './common'

const appLoading = document.querySelector('#app-loading')
Vue.use(Router)

// 修改路由 push 方法，禁止跳转相同路由时报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 创建路由实例
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: (to, from, savedPosition) => (savedPosition || { x: 0, y: 0 }),
  scrollBehavior: (to, from, savedPosition) => { // 兼容线路列表页动态修改 url 的 query 参数导致滚动条重置问题
    if ((to.path === from.path) && (to.path === '/quoted-price-new/schemes')) {
      return {}
    } else {
      return (savedPosition || { x: 0, y: 0 })
    }
  },
  routes: [
    ...home,
    ...events,
    ...myCenter,
    ...common
  ]
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  appLoading.style.display = 'flex' // 显示加载动画
  LoadingBar.start()

  // 路由报错刷新处理
  const targetPath = localStorage.getItem('router:targetPath')
  if (targetPath) {
    localStorage.removeItem('router:targetPath')
    const reloadCount = sessionStorage.getItem('router:reloadCount')
    if (!reloadCount) {
      router.replace(targetPath)
      sessionStorage.setItem('router:reloadCount', 1)
      return
    }
  }

  // TODO:去除 quoted-price-new 页面参数
  let { token, username } = to.query
  if (token) {
    wj.setLoginCookie({ token, username })
  }
  if (to.path === '/quoted-price-new' && token && username) {
    router.push({ name: 'quoted-price-new' })
  }
  if (store.state.order.isDraftsAuto) {
    store.commit('order/setIsDraftsAuto', false)
  } else {
    // if (from.path === '/place-order' && to.path !== '/place-order') {
    //   store.commit('order/setShowDrafts', true)
    //   store.commit('order/setRouterTo', to)
    //   LoadingBar.finish()
    //   return false
    // }
  }

  let isOldVersion = typeof (store.state.app.isOldVersion) === 'boolean' ? store.state.app.isOldVersion : wj.store('isOldVersion')
  if (!isOldVersion) {
    if (to.path === '/home') router.push({ name: 'index-new' })
    if (to.path === '/quoted-price') router.push({ name: 'quoted-price-new' })
    // if (to.path === '/service-provider/details') router.push({ name: 'service-provider-details-redesign' })
  } else if (isOldVersion) {
    if (to.path === '/') router.push({ name: 'index' })
    if (to.path === '/quoted-price-new') router.push({ name: 'quoted-price' })
    // if (to.path === '/service-provider/details-redesign') router.push({ name: 'service-provider-details' })
  }

  // 登录权限控制
  if (to.meta.needAuth && !wj.isLogin()) {
    LoadingBar.finish()
    // let path = from.meta.needAuth ? '/' : from.path
    // next({ replace: true, path })
    // router.replace(path)
    router.replace('/')
    wj.login()
    return
  }

  // 访问权限控制
  if (to.meta.access && to.meta.access !== wj.params.role) {
    next({ name: 'page404' })
  }

  next()
})

// 全局后置钩子
router.afterEach((to, from) => {
  // 缓存页面来源数据
  let fromObj = { path: from.path, title: from.meta.title, productType: from.meta && from.meta.productType }
  if (!fromObj.title && fromObj.path === '/') {
    // 获取界面来源title和path
    try {
      if (window.opener && window.opener.document) {
        const title = window.opener.document.title
        const path = window.opener.document.location && window.opener.document.location.pathname
        fromObj.path = path
        fromObj.title = title
      }
    } catch (e) {
      fromObj.path = '/'
      fromObj.title = '跨境好运_首页'
    }
  }
  wj.session('frompath', fromObj)

  // 页面统计
  let toPath = to.fullPath
  let fromPath = from.fullPath
  let origin = window.location.origin
  if (toPath !== fromPath) {
    istat.report({ action: '_LEAVE-PAGE', event: 'route', page: origin + fromPath })
    store.dispatch('app/reportPageViewTime', {
      name: '直客端页面_加载曝光时长',
      heart_seconds: store.state.app.pageViewTimeCount * 3,
      from_path: JSON.stringify(fromObj),
      page_title: from.meta.title,
      product_type: fromObj.productType
    })
  }
  istat.report({ action: '_ENTER-PAGE', event: 'route', page: origin + toPath })
  store.dispatch('app/reportPageViewCount', {
    name: '直客端页面_加载曝光次数',
    from_path: JSON.stringify(fromObj),
    page_title: to.meta.title,
    page: to.path,
    product_type: to.meta && to.meta.productType
  })
  stat.trackPageview(toPath)

  appLoading.style.display = 'none' // 隐藏加载动画
  LoadingBar.finish()
  if (to.meta.title) document.title = to.meta.title
  window.scrollTo(0, 0)
  sessionStorage.removeItem('router:reloadCount')

  // 加盟商拉新用户只允许进我的订单页面
  let userType = (store && store.state && store.state.user && store.state.user.userInfo && store.state.user.userInfo.userType) || ''
  if (wj.isLogin() && +userType === 4 && to.name !== 'my-center-order' && to.name !== 'order-detail') {
    router.push({ name: 'my-center-order' })
  }
})

// 监听路由报错
router.onError((err) => {
  // 路由报 Loading chunk {n} failed 时刷新页面，尝试刷新两次
  // 必须用 reload 刷新，其他方式刷新，请求的依然是原来的资源
  const reg = /Loading (\w+ )?chunk (\S)+ failed/ig
  const isChunkLoadFailed = reg.test(err.message)
  if (isChunkLoadFailed) {
    const reloadCount = +sessionStorage.getItem('router:reloadCount')
    const targetPath = router.history.pending.fullPath
    // if (location.hash === '#reloadonce') {
    //   location.hash = '#reloadonce'
    //   location.reload()
    // }
    if (reloadCount < 2) {
      localStorage.setItem('router:targetPath', targetPath)
      sessionStorage.setItem('router:reloadCount', reloadCount + 1)
      location.reload()
      return
    }
    sessionStorage.removeItem('router:reloadCount')
  }
})

const routerPush = router.push
router.push = (param) => {
  if (param.blank) {
    const { href } = router.resolve(param)
    window.open(href, '_blank')
  } else {
    routerPush.call(router, param)
  }
}

export default router
