<template>
  <svg class="iconsvg" :class="className" aria-hidden="true">
    <use :href="iconName" />
  </svg>
</template>

<script>
  export default {
    name: 'iconsvg',
    props: {
      name: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName () {
        return `#${this.name}`
      },
      className () {
        return `icon-${this.name}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .iconsvg
    width 1em
    height 1em
    overflow hidden
    vertical-align -0.15em
    fill currentColor
</style>
