import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import locale from './validator-locale'
const idCardReg = /^[1-9]\d{7}((0\d)|bai(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
Validator.localize(locale)
Validator.extend('mobile', {
  getMessage: field => field + '格式不正确！',
  validate: value => /^1[3-9]\d{9}$/.test(value)
})
Validator.extend('tel', {
  getMessage: field => field + '格式不正确！',
  validate: value => /^(([0+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-\d{3,})?$/.test(value)
})
Validator.extend('phone', {
  getMessage: field => field + '格式不正确！',
  validate: value => /(^(([0+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-\d{3,})?$)|(^1[3-9]\d{9}$)/.test(value)
})
Validator.extend('password', {
  getMessage: field => field + '格式不正确！(字母和数字组合，长度不少于6位)',
  validate: value => /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{6,32}$|^(?![0-9]+$)(?![!,.@#$%^&*?]+$)[0-9!,.@#$%^&*]{6,32}$|^(?![!,.@#$%^&*?]+$)(?![a-zA-Z]+$)[a-zA-Z!,.@#$%^&*]{6,32}$/.test(value)
  // validate: value => /^[(\S+)|(\s+)]{6,40}$/.test(value)
})
// const reg = /[\u4E00-\u9FA5\uF900-\uFA2D]/ // 匹配必须含有中文
// const reg2 = /^[a-zA-Z]+$/ // 验证必须含有字母
Validator.extend('nameCn', {
  getMessage: field => field + '必须包含中文',
  validate: value => /[\u4E00-\u9FA5\uF900-\uFA2D]/.test(value)
})
Validator.extend('nameCnOrEn', {
  getMessage: field => field + '必须包含中文或英文',
  validate: value => /[\u4E00-\u9FA5\uF900-\uFA2Da-zA-Z]/.test(value)
})
Validator.extend('nameEn', {
  getMessage: field => field + '必须包含字母',
  // validate: value => /^\w+[\s\w+]+$/.test(value)
  validate: value => /[a-zA-Z]+/.test(value)
})
Validator.extend('nameEnOrNum', {
  getMessage: field => field + '必须包含英文或者数字',
  validate: value => /[a-zA-Z0-9]+/.test(value)
})
// 海关编码 10位数字
Validator.extend('customsCode', {
  getMessage: field => field + '必须包含字母',
  validate: value => /^[0-9]{10}$/.test(value)
})
Validator.extend('number', {
  getMessage: field => field + '大于0',
  validate: value => value > 0
})
Validator.extend('idCard', {
  getMessage: field => field + '格式不正确',
  validate: value => idCardReg.test(value)
})
Validator.extend('content', {
  getMessage: field => field + '字数不能少于10个',
  validate: value => value.length > 10
})
Validator.extend('onlyChOrEn', {
  getMessage: field => field + '字数不能少于10个',
  validate: value => {
    let flag = /[`~!@#$^&*()=|{}':;',.<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]/
    return !flag.test(value)
  }
})
Vue.use(VeeValidate, {
  fieldsBagName: 'vfields',
  events: 'blur',
  // inject: true,
  locale: 'zh_CN'
})
