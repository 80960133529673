// 全局状态模块
import mutations from './mutations'
import actions from './actions'

const state = {
  searchParams: {},
  orderParams: {},
  uploadFiles: [],
  payResult: {},
  boxType: '',
  // channelNoticeList: ['包税', '包查验费', '分抛30%', 'X截X开', '需贴MADE IN CHINA'],
  channelNoticeList: [],
  cartons: null,
  shippingAddressObject: {},
  refNumber: '',
  remark: '',
  order: {
    shippingAddress: {},
    deliveryAddress: {}
  },
  orderGoodsType: 'common',
  fileList: [],
  orderExcel: null, // 上传订单模板的file
  price: 0,
  priceDetail: null, // 收费明细
  totalWeight: 0, // 箱子的重量相加之和
  billedTotalWeight: 0, // 计费重
  file: '',
  fillOrder: {},
  increase: '',
  totalAmount: 0,
  packageFile: '', // 上传装箱单文件
  channelId: '', // 渠道id
  cityId: '', // 城市id
  purposeCountry: '', // 目的国家code
  uploadVatInfo: {}, // 上传装箱单后获取的vat信息
  offerParams: JSON.parse(sessionStorage.getItem('offerParams')) || {},
  addrData: {},
  orderAddrInfo: {},
  addrInfo: {}, // 地址信息
  addrErrInfo: {},
  // sendCity: JSON.parse(sessionStorage.getItem('sendCity')) || {} // 首页发货城市
  sendCity: {}, // 首页发货城市
  guideObjInfoPlaceOrder: { // 下单页引导信息
    index: 0,
    list: [
      'consigneeInfoTag', // 收货人信
      'consignorInfoTag', // 发货人信息
      'addGoodesTag', // 添加货物
      'excelUploadTag' // 我要用excel上传
    ]
  },
  contractUrl: '', // 订单委托书的pdfurl
  rootParams: {}, // 询价页面最高权限参数，url获取或者分享链接获取
  orderTip: false,
  orderStore: {}, // 下单页选择的班期信息
  // 草稿箱变量
  showDrafts: false, // 是否显示草稿箱
  isDraftsAuto: false, // 是否存在过滤草稿箱的权限
  routerTo: {} // 当前跳转的路由
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
