<template>
  <RouterView id="app" v-if="isRouterAlive" class="app" :class="{'in-saas-iframe': $isInSaasIframe}" />
</template>

<script>
  import { throttle } from 'lodash'
  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    data() {
      return {
        isRouterAlive: true
      }
    },

    computed: {
      ...mapState('app', ['pageViewTimeCount'])
    },

    created() {
      // 接收聊天窗口消息并执行相应方法
      window.addEventListener('message', (event) => {
        const action = this[event.data?.action]
        if (typeof action === 'function') action()
      })
    },

    mounted() {
      // 更新页面可见性状态
      document.addEventListener('visibilitychange', this.handleVisiable)
      // 页面直接点击关闭按钮
      window.onunload = this.reportViewTime
    },

    destroyed() {
      document.removeEventListener('visibilitychange', this.handleVisiable)
    },

    methods: {
      ...mapActions('app', ['getInfoList', 'reportPageViewCount', 'reportPageViewTime']),
      ...mapMutations('app', ['setShowIframe', 'setNewMessage']),

      // 刷新页面
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      },

      // 关闭聊天窗口
      closeChatWindow() {
        this.setNewMessage(0)
        this.getInfoList()
        this.setShowIframe(false)
      },

      linkToMessagePage() {
        this.closeChatWindow()
        this.$router.push('/my-center/message')
      },

      handleVisiable(e) {
        if (e.target.visibilityState === 'hidden') {
          this.reportViewTime()
        }
        if (e.target.visibilityState === 'visible') {
          this.reportPageViewCount({
            name: '直客端页面_加载曝光次数',
            from_path: JSON.stringify(this.$wj.session('frompath')),
            page_title: document.title,
            product_type: this.$route.meta && this.$route.meta.productType
          })
        }
      },

      reportViewTime: throttle(function() {
        this.reportPageViewTime({
          name: '直客端页面_加载曝光时长',
          from_path: JSON.stringify(this.$wj.session('frompath')),
          heart_seconds: this.pageViewTimeCount * 3,
          product_type: this.$route.meta && this.$route.meta.productType
        })
      }, 100)
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@/styles/iniframe'
  .app
    position relative
    box-sizing border-box
    min-height 100%
    color $black
    min-width $design-width
    clearfix() // 触发 BFC
    background $bgcolor
</style>
