import wj from '@/plugins/wjtools'

export default {
  setOrderParams (state, payload) {
    state.orderParams = wj.util.cloneObject(payload)
  },
  setSearchParams (state, payload) {
    state.searchParams = wj.util.cloneObject(payload)
  },
  setUploadFiles (state, payload) {
    state.uploadFiles = wj.util.cloneObject(payload)
  },
  setPayResult (state, payload) {
    state.payResult = payload
  },
  setBoxType (state, payload) {
    state.boxType = payload
  },
  setChannelNoticeList (state, payload) {
    state.channelNoticeList = payload
  },
  setCartons (state, payload) {
    state.cartons = payload
  },
  setShippingAddressObject (state, payload) {
    state.shippingAddressObject = payload
  },
  setRefNumber (state, payload) {
    state.refNumber = payload
  },
  setRemark (state, payload) {
    state.remark = payload
  },
  setOrder (state, payload) {
    state.order = payload
  },
  setTotalWeight (state, payload) {
    state.totalWeight = payload
  },
  setBilledTotalWeight (state, payload) {
    state.billedTotalWeight = payload
  },
  setPrice (state, payload) {
    state.price = payload
  },
  setPriceDetail (state, payload) {
    state.priceDetail = payload
  },
  setOrderExcel (state, payload) {
    state.orderExcel = payload
  },
  setFileList (state, payload) {
    state.fileList = payload
  },
  setFile (state, payload) {
    state.file = payload
  },
  setFillOrder (state, payload) {
    state.fillOrder = payload
  },
  setIncrease (state, payload) {
    state.increase = payload
  },
  setTotalAmount (state, payload) {
    state.totalAmount = payload
  },
  setPackageFile (state, payload) {
    state.packageFile = payload
  },
  setContractUrl (state, payload) {
    state.contractUrl = payload
  },
  setChannelId (state, payload) {
    state.channelId = payload
  },
  setCityId (state, payload) {
    state.cityId = payload
  },
  setPurposeCountry (state, payload) {
    state.purposeCountry = payload
  },
  setUploadVatInfo (state, payload) {
    state.uploadVatInfo = payload
  },
  setAddrData(state, payload) {
    state.addrData = payload
  },
  setOrderAddrInfo(state, payload) {
    state.orderAddrInfo = payload
  },
  setOfferParams(state, payload) {
    state.offerParams = payload
  },
  setAddrInfo(state, payload) {
    state.addrInfo = payload
  },
  setAddrErrInfo(state, payload) {
    state.addrErrInfo = payload
  },
  setSendCity(state, payload) {
    state.sendCity = payload
  },
  guideToNext(state, val) { // ?????
    if (val === 0) {
      state.guideObjInfoPlaceOrder.index = 0
      return
    }
    ++state.guideObjInfoPlaceOrder.index
  },
  setRootParams(state, payload) {
    state.rootParams = payload
  },
  setOrderTip(state, payload) {
    state.orderTip = payload
  },
  setOrderStore(state, payload) {
    state.orderStore = payload
  },
  setOrderGoodsType(state, payload) {
    state.orderGoodsType = payload
  },
  setShowDrafts (state, payload) {
    state.showDrafts = payload
  },
  setIsDraftsAuto(state, payload) {
    state.isDraftsAuto = payload
  },
  setRouterTo(state, payload) {
    state.routerTo = payload
  }

}
