import stat from './index'
import { notChanged, isEmpty } from './utils'

export default function (el, binding) {
  if (notChanged(binding) || isEmpty(binding)) return

  if (el.removeEventListeners && typeof el.removeEventListeners === 'function') {
    el.removeEventListeners()
  }

  // use modifier as events
  const events = Object.keys(binding.modifiers).map(modifier => {
    if (binding.modifiers[modifier]) {
      return modifier
    }
  })
  if (!events.length) events.push('click') // listen click event by default

  // passing parameters as object
  // let args = []
  // if (typeof binding.value === 'object') {
  //   const value = binding.value
  //   if (value.name) args.push(value.name)
  //   if (value.event) args.push(value.event)
  //   if (value.msg) args.push(value.msg)

  //   // passing parameters as string separate by comma
  // } else if (typeof binding.value === 'string') {
  //   args = binding.value.trim()
  // }

  // addEventListener for each event, call trackEvent api
  const listeners = []
  events.forEach((event, index) => {
    listeners[index] = () => stat.report(binding.value)
    el.addEventListener(event, listeners[index], false)
  })

  // a function to remove all previous event listeners in update cycle to prevent duplication
  el.removeEventListeners = () => {
    events.forEach((event, index) => {
      el.removeEventListener(event, listeners[index])
    })
  }
}
