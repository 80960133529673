// 活动页面路由表
export default [
  {
    path: '/events',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '/events/double-day-detail',
        name: 'double-day-detail',
        component: () => import('@/views/events/double-day/double-day-detail'),
        meta: {
          title: '跨境好运-双旦活动详情'
        }
      },
      {
        path: '/events/new-user',
        name: 'new-user',
        component: () => import('@/views/events/new-user'),
        meta: {
          title: '跨境好运-活动页：新用户注册活动'
        }
      },
      {
        path: '/events/red-packet',
        name: 'red-packet',
        component: () => import('@/views/events/red-packet'),
        meta: {
          title: '跨境好运-开工红包活动'
        }
      },
      {
        path: '/events/fight-against-epidemic',
        name: 'fight-against-epidemic',
        component: () =>
          import('@/views/events/fight-against-epidemic/index-new'),
        meta: {
          title: '跨境好运-抗击疫情'
        }
      },
      {
        path: '/events/statement',
        name: 'events-statement',
        component: () => import('@/views/events/statement'),
        meta: {
          // needAuth: true,
          title: '跨境好运-公司收款账户声明'
        }
      },
      {
        path: '/events/active618',
        name: 'events-active618',
        component: () => import('@/views/events/active618'),
        meta: {
          needAuth: true,
          title: '跨境好运-618活动'
        }
      },
      {
        path: '/events/anniversary',
        name: 'events-anniversary',
        component: () => import('@/views/events/anniversary-one'),
        meta: {
          // needAuth: true,
          title: '跨境好运-周年庆活动'
        }
      },
      {
        path: '/events/anniversary-back',
        name: 'events-anniversary-back',
        component: () => import('@/views/events/anniversary-one-back'),
        meta: {
          // needAuth: true,
          title: '跨境好运-周年庆返场活动'
        }
      },
      {
        path: '/new-year-introduce',
        component: () => import('@/views/events/new-year/new-year-introduce'),
        children: [
          {
            path: '/',
            name: 'new-year-introduce',
            component: () =>
              import('@/views/events/new-year/new-year-introduce'),
            meta: {
              title: '跨境好运迎春福利'
            }
          }
        ]
      },
      {
        path: '/subsidy',
        component: () => import('@/views/events/active-subsidy'),
        children: [
          {
            path: '/',
            name: 'subsidy',
            component: () => import('@/views/events/active-subsidy'),
            meta: {
              title: '补贴活动'
            }
          }
        ]
      },
      {
        path: '/active817',
        component: () => import('@/views/events/active817'),
        children: [
          {
            path: '/',
            name: 'active817',
            component: () => import('@/views/events/active817'),
            meta: {
              title: '跨境好运-817'
            }
          }
        ]
      },
      {
        path: '/activity-nov',
        name: 'activity-nov',
        component: () => import('@/views/events/activity-November'),
        meta: {
          title: '跨境好运-11月活动'
        }
      },
      {
        path: '/events/activity-mar',
        name: 'activity-mar',
        component: () => import('@/views/events/activity-mar'),
        meta: {
          title: '跨境好运-“新”享事成活动'
        }
      },
      {
        path: '/year-end-bonus',
        name: 'year-end-bonus',
        component: () => import('@/views/events/year-end-bonus'),
        meta: {
          title: '跨境好运-12月年终奖红包活动'
        }
      }
    ]
  },
  {
    path: '/events',
    component: () => import('@/views/events/double-day/double-day-layout'),
    children: [
      {
        path: '/events/double-day',
        name: 'double-day',
        component: () => import('@/views/events/double-day/double-day'),
        meta: {
          title: '跨境好运-双旦活动'
        }
      }
    ]
  },
  {
    path: '/events',
    component: () => import('@/views/layout'),
    children: [
      {
        path: 'good-start',
        name: 'good-start',
        component: () => import('@/views/events/good-start'),
        meta: {
          title: '跨境好运-开门红'
        }
      }
    ]
  },
  {
    path: '/about-kgj',
    component: () => import('@/views/events/about-kgj'),
    children: [
      {
        path: '/about-kgj',
        name: 'about-kgj',
        component: () => import('@/views/events/about-kgj'),
        meta: {
          title: '跨境好运'
        }
      }
    ]
  },
  {
    path: '/kgj-service',
    component: () => import('@/views/events/kgj-service'),
    children: [
      {
        path: '/kgj-service',
        name: 'kgj-service',
        component: () => import('@/views/events/kgj-service'),
        meta: {
          title: '跨境好运'
        }
      }
    ]
  },
  {
    path: '/map-test',
    name: 'map-test',
    component: () => import('@/views/events/gaode-test'),
    meta: {
      title: '跨境好运-高德地图'
    }
  }
]
