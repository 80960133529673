// import wj from '@/plugins/wjtools'
import * as api from '@/api'
import { Message } from 'view-design'
// import { debounce } from 'lodash'

export default {
  // 获取侧边栏收藏夹列表
  async getSlideList({ commit }, params) {
    if (!params.type) return
    const res = await api.favoritesList(params)
    const data = res.data || []
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
    if (params.type === 1) {
      commit('setStateVal', {
        channelsSlideList: data
      })
    } else {
      commit('setStateVal', {
        schemeSlideList: data
      })
    }
  },
  // 加入收藏夹
  async addFavorites({ state, dispatch, commit }, params) {
    const res = await api.favoritesInsert(params)
    dispatch('getSlideList', params)
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
  },
  // 删除/清空收藏夹 - 不传id表示清空
  async deleteFavorites({ dispatch }, params) {
    const res = await api.favoritesDelete(params)
    dispatch('getSlideList', params)
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
  }
}
