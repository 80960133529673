// TODO:用户状态模块
import mutations from './mutations'
import * as actions from './actions'
import { isLogin } from '@/plugins/wjtools/user'
let userInfo = (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))) || {}
const state = {
  token: '',
  userInfo: isLogin() ? userInfo : {}, // 用户信息
  username: '',
  userLocation: {},
  activeStatusOf520: {
    isUserFor520: false,
    showActiveModal: false
  },
  activeStatusOf618: {
    isUserFor618: false,
    showActiveModal: false
  },
  // 用户是否已经浏览引导弹窗 false未浏览，true已浏览
  userGuideInfo: actions.getUserGuideInfoInLocalStorage(),
  nickName: '' // 用户昵称
  // userGuideInfo: {
  //   quetePrice: true, // 询价页面
  //   palceOrder: true // 下单页
  // }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
