// 有页眉页脚的页面路由
export default [{
  path: '/',
  component: () => import('@/views/layout'),
  children: [{
    path: '',
    name: 'index-new',
    component: () => import('@/views/home/home-redesign'),
    meta: {
      title: '跨境好运_首页'
    }
  },
  {
    path: '/home',
    redirect: '/',
    name: 'index'
    // component: () => import('@/views/home/home-new'),
  }, {
    path: '/quoted-price',
    redirect: '/quoted-price-new',
    name: 'quoted-price'
    // component: () => import('@/views/home/quoted-price'),
  }, {
    path: '/home-page-zone',
    name: 'home-page-zone',
    component: () => import('@/views/home/home-page-zone'),
    meta: {
      needAuth: false,
      title: '跨境好运-专区列表',
      productType: 1
    }
  }, {
    path: '/quoted-price-new',
    name: 'quoted-price-new',
    component: () => import('@/views/home/quoted-price/index-new'),
    meta: {
      needAuth: false,
      title: '跨境好运-方案列表',
      productType: 0
    }
  }, {
    path: '/quoted-price-small',
    name: 'quoted-price-small',
    component: () => import('@/views/home/small-bag-superbuy'),
    meta: {
      needAuth: false,
      title: '跨境好运-小包-方案列表',
      productType: 1
    }
  }, {
    path: '/small-bag-order',
    name: 'small-bag-order',
    component: () => import('@/views/home/small-bag-superbuy/excel-upload'),
    meta: {
      needAuth: false,
      title: '跨境好运-小包-下单',
      productType: 1
    }
  }, {
    path: '/quoted-price-new/schemes',
    name: 'quoted-price-new-schemes',
    component: () => import('@/views/home/quoted-price/scheme-list'),
    meta: {
      needAuth: false,
      title: '跨境好运-渠道列表',
      productType: 0
    }
  }, {
    path: '/quoted-price-new/channel-compare',
    name: 'channel-compare',
    component: () => import('@/views/home/quoted-price/channel-compare'),
    meta: {
      needAuth: true,
      title: '跨境好运-方案对比'
    }
  }, {
    path: '/quoted-price-new/scheme-compare',
    name: 'scheme-compare',
    component: () => import('@/views/home/quoted-price/scheme-compare'),
    meta: {
      needAuth: true,
      title: '跨境好运-渠道对比'
    }
  }, {
    path: '/little-quoted-price',
    name: 'little-quoted-price',
    component: () => import('@/views/home/quoted-price/little-index'),
    meta: {
      needAuth: true,
      title: '跨境好运-小包报价'
    }
  }, {
    path: '/quoted-price-detail',
    name: 'quoted-price-detail',
    component: () => import('@/views/home/quoted-price'),
    meta: {
      needAuth: true,
      title: '跨境好运-报价详情列表'
    }
  }, {
    path: '/offer-detail',
    name: 'offer-detail',
    component: () => import('@/views/home/quoted-price/offer-detail'),
    meta: {
      needAuth: true,
      title: '跨境好运-方案详情'
    }
  }, {
    path: '/detail-little',
    name: 'detail-little',
    component: () => import('@/views/home/quoted-price/detail-little'),
    meta: {
      needAuth: true,
      title: '跨境好运-报价详情'
    }
  }, {
    path: '/place-order',
    name: 'place-order',
    component: () => import('@/views/home/place-order/place-order'),
    meta: {
      // keepAlive: true,
      needAuth: true,
      title: '跨境好运-立即下单',
      productType: 0
    }
  }, {
    path: '/place-order-small',
    name: 'place-order-small',
    component: () => import('@/views/home/place-order/place-order-small'),
    meta: {
      // keepAlive: true,
      needAuth: true,
      title: '跨境好运-下单-小包'
    }
  }, {
    path: '/routeoff-tips',
    name: 'routeoff-tips',
    component: () => import('@/views/home/place-order/routeOff-tips'),
    meta: {
      // keepAlive: true,
      needAuth: true,
      title: '线路已下架'
    }
  }, {
    path: '/traditional-place-order',
    name: 'traditional-place-order',
    component: () => import('@/views/home/place-order/traditional-place-order'),
    meta: {
      // keepAlive: true,
      needAuth: true,
      title: '跨境好运-传统联运下单'
    }
  }, {
    path: '/success',
    name: 'success',
    component: () => import('@/views/home/place-order/place-order-success'),
    meta: {
      needAuth: true,
      title: '跨境好运-下单成功'
    }
  }, {
    path: '/track-order',
    name: 'track-order',
    component: () => import('@/views/home/track-order/index'),
    meta: {
      needAuth: false,
      title: '跨境好运-货物跟踪'
    }
  }, {
    path: '/terminal-client-saas',
    name: 'terminal-client-saas',
    component: () => import('@/views/home/terminal-client-saas/index'),
    meta: {
      needAuth: false,
      title: '跨境好运-好运管家'
    }
  }, {
    path: '/service',
    name: 'service',
    component: () => import('@/views/home/service/service'),
    meta: {
      needAuth: false,
      title: '跨境好运-查货代'
    }
  }, {
    path: '/platform',
    name: 'platform',
    component: () => import('@/views/home/platform'),
    meta: {
      title: '跨境好运-关于我们',
      menu: 'about'
    }
  }, {
    path: '/live',
    name: 'live',
    component: () => import('@/views/home/live.vue'),
    meta: {
      title: '跨境好运-活动',
      menu: 'about'
    }
  }, {
    path: '/service-guarantee',
    name: 'service-guarantee',
    component: () => import('@/views/home/service-guarantee'),
    meta: {
      title: '跨境好运-服务保障',
      menu: 'about'
    }
  }, {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/home/faqs'),
    meta: {
      title: '跨境好运-常见问题',
      menu: 'about'
    }
  }, {
    path: '/cargo-query',
    name: 'cargo-query',
    component: () => import('@/views/home/cargo-query'),
    meta: {
      title: '跨境好运-货物查询'
    }
  }, {
    path: '/order-detail',
    name: 'order-detail',
    component: () => import('@/views/home/order-detail'),
    meta: {
      title: '跨境好运-订单详情'
    }
  }, {
    path: '/service-provider',
    name: 'service-provider',
    component: () => import('@/views/home/service-provider'),
    meta: {
      // needAuth: true,
      title: '跨境好运-货代'
    }
  }, {
    path: '/service-provider/details',
    name: 'service-provider-details',
    component: () => import('@/views/home/service-provider/details'),
    meta: {
      // needAuth: true,
      title: '跨境好运-货代详情'
    }
  }, {
    path: '/one-price-search',
    name: 'one-price',
    component: () => import('@/views/home/one-price/search-price'),
    meta: {
      needAuth: false,
      title: '跨境好运-一口价列表'
    }
  },
  {
    path: '/double-festival',
    name: 'double-festival',
    component: () => import('@/views/events/double-festival'),
    meta: {
      needAuth: false,
      title: '跨境好运-双节活动'
    }
  }, {
    path: '/vip-join',
    name: 'vip-join',
    component: () => import('@/views/events/vip-join'),
    meta: {
      needAuth: false,
      title: '跨境好运-货代入驻'
    }
  }, {
    path: '/vip-join-history',
    name: 'vip-join-history',
    component: () => import('@/views/events/vip-join/history'),
    meta: {
      needAuth: false,
      title: '跨境好运-货代信息提交纪录'
    }
  }, {
    path: '/bd-form',
    name: 'bd-form',
    component: () => import('@/views/events/bd-form'),
    meta: {
      needAuth: false,
      title: '跨境好运-客户信息收集调查'
    }
  }, {
    path: '/bd-history',
    name: 'bd-history',
    component: () => import('@/views/events/bd-form/history'),
    meta: {
      needAuth: false,
      title: '跨境好运-客户信息提交纪录'
    }
  }, {
    path: '/logistics-provider',
    name: 'logistics-provider',
    component: () => import('@/views/home/logistics-provider'),
    meta: {
      needAuth: false,
      needReload: true,
      title: '跨境好运-货代详情'
    }
  },
  {
    path: '/service-provider/details-redesign',
    name: 'service-provider-details-redesign',
    component: () => import('@/views/home/service-provider-redesign/details'),
    meta: {
      // needAuth: true,
      title: '跨境好运-方案详情'
    }
  }, {
    path: '/snapshot-order',
    name: 'snapshot-order',
    component: () => import('@/views/my-center/order/snapshot-tpl'),
    meta: {
      needAuth: false,
      needReload: true,
      title: '跨境好运-下单快照'
    }
  }, {
    path: '/snapshot-fee',
    name: 'snapshot-fee',
    component: () => import('@/views/my-center/order/snapshot-tpl/fee-snapshot'),
    meta: {
      needAuth: false,
      needReload: true,
      title: '跨境好运-下单快照'
    }
  }, {
    path: '/integral-mall',
    name: 'integral-mall',
    component: () => import('@/views/my-center/integral/mall'),
    meta: {
      needAuth: false,
      title: '跨境好运-积分商城'
    }
  }, {
    path: '/integral-redeem-record',
    name: 'integral-redeem-record',
    component: () => import('@/views/my-center/integral/mall/redeem-record'),
    meta: {
      needAuth: false,
      title: '跨境好运-积分兑换记录'
    }
  }, {
    path: '/container-search',
    name: 'container-search',
    component: () => import('@/views/home/container/search/search'),
    meta: {
      needAuth: false,
      title: '跨境好运-整柜',
      productType: 2
    }
  }, {
    path: '/container-place-order',
    name: 'container-place-order',
    component: () => import('@/views/home/container/place-order/place-order'),
    meta: {
      needAuth: true,
      title: '跨境好运-整柜下单',
      productType: 2
    }
  }, {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/home/privacy'),
    meta: {
      needAuth: false,
      title: '跨境好运-用户隐私政策'
    }
  }, {
    path: '/user-service',
    name: 'user-service',
    component: () => import('@/views/home/user-service'),
    meta: {
      needAuth: false,
      title: '跨境好运-平台用户服务协议'
    }
  }, {
    path: '/active-list/:activityId',
    name: 'active-list',
    component: () => import('@/views/home/active-list/index'),
    meta: {
      needAuth: true,
      title: '跨境好运-活动列表页'
    }
  }]
}]
