import wj from '@/plugins/wjtools'

export default {
  setServerTime(state, time) {
    state.serverTime = time
  },

  upLoginState(state) {
    state.isLogin = wj.isLogin()
    state.username = wj.cookie('username')
    // state.role = wj.cookie('role')
  },
  setOutLogin(state) {
    wj.cookie('token', '')
    wj.cookie('username', '')
    state.isLogin = false
  },

  setWxConfigState(state, payload) {
    state.wxConfigState = payload
  },

  setFbaData(state, payload) {
    state.fbaData = payload
  },
  setFbaFilterData(state, payload) {
    state.fbaFilterData = payload
  },

  setCountryData(state, payload) {
    state.countryData = payload
  },
  setUnFillOrder(state, payload) {
    state.unFillOrder = payload
  },
  setShowOrder(state, payload) {
    state.showOrder = payload
  },
  setShowTips(state, payload) {
    state.showTips = payload
  },
  setTipsType(state, payload) {
    state.tipsType = payload
  },
  setUserStatus(state, payload) {
    state.userStatus = payload
  },
  setHomeTxt(state, payload) {
    state.homeTxt = payload
  },
  setShowWaitPay(state, payload) {
    state.showWaitPay = payload
  },
  setWaitPayOrder(state, payload) {
    state.waitPayOrder = payload
  },
  setShowActive(state, payload) {
    state.showActive = payload
  },
  setShowLogin(state, payload) {
    if (payload && state.showLogin) {
      state.showLogin = false
    }
    state.showLogin = payload
  },
  setBillInfo(state, payload) {
    state.billInfo = payload
  },
  setShowGlobal(state, payload) {
    state.showGlobal = payload
  },
  setLoginType(state, payload) {
    state.loginType = payload
  },
  setShowInviteRedpackage(state, payload) {
    state.showInviteRedpackage = payload
  },
  setShowActiveEight(state, payload) {
    state.showActiveEight = payload
  },
  setShowIframe(state, payload) {
    state.showIframe = payload
  },
  setNewMessage(state, payload) {
    state.hasNewMessage = payload
  },
  setNewMessageIM(state, payload) {
    state.hasNewMessageIM = payload
  },
  setIframeParams(state, {
    shopId = '',
    shopIdName = '',
    imOrderId = '',
    order = '',
    channelId = '',
    channelLibId = '',
    cityId = '',
    countryCode = '',
    warehouseAddress = '',
    warehouseType = '',
    totalWeight = '',
    totalVolume = '',
    origin = '',
    storeId = '',
    storeNum = '',
    imOrderType = ''
  }) {
    state.shopId = shopId
    state.shopIdName = shopIdName
    state.imOrderId = imOrderId
    state.order = order
    state.channelId = channelId
    state.channelLibId = channelLibId
    state.cityId = cityId
    state.countryCode = countryCode
    state.warehouseAddress = warehouseAddress
    state.warehouseType = warehouseType
    state.totalWeight = totalWeight
    state.totalVolume = totalVolume
    state.origin = origin
    state.storeId = storeId
    state.storeNum = storeNum
    state.imOrderType = imOrderType
  },
  setShopId(state, payload) {
    state.shopId = payload
  },
  setShopIdName(state, payload) {
    state.shopIdName = payload
  },
  setImOrderId(state, payload) {
    state.imOrderId = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setHomeScrollTop(state, payload) {
    state.homeScrollTop = payload
  },
  setUnreadCount(state, payload) {
    state.noReadCount = payload
  },
  setNotifyList(state, payload) {
    state.notifyList = payload
  },
  setNotifyDetailList(state, payload) {
    state.notifyDetailList = payload
  },
  setShowAccount(state, payload) {
    state.showAccount = payload
  },
  setShowAccountList(state, payload) {
    state.showAccountList = payload
  },
  setShowBillTips(state, data) { // 账期提示弹框
    state.showBillTips = data
  },
  setPaymentMethodVisible(state, data) { // 账期配置
    state.paymentMethodVisible = data
  },
  setVerifyInfo(state, data) { // 认证信息
    state.verifyInfo = data
  },
  setShowVerifyTips(state, data) { // 认证提示弹框
    state.showVerifyTips = data
  },
  setIsOverNum(state, data) { // 是否过10单
    state.isOverNum = data
  },
  setShowPlatformTip(state, data) { // 平台公告
    state.showPlatformTip = data
  },
  setCanPlaceOrder(state, data) {
    state.canPlaceOrder = data
  },
  setOnlogin(state, payload) {
    state.onlogin = payload
  },
  setDialogFormVisible(state, payload) {
    state.dialogFormVisible = payload
  },
  setIsPrecisePrice(state, payload) {
    state.isPrecisePrice = payload
  },
  setPreciseObj(state, payload) {
    state.preciseObj = payload
  },
  setFormParams(state, payload) {
    state.formParams = payload
  },
  setSearchStatus(state, payload) {
    state.searchStatus = payload
  },
  setIsSpace(state, payload) {
    state.isSpace = payload
  },
  setShowNewCustomerCoupon(state, payload) {
    state.showNewCustomerCoupon = payload
  },
  setPageViewTimeCount(state, data) { // 设置曝光时长
    state.pageViewTimeCount = data
  },
  setContainerPort(state, data) {
    state.containerPort = data
  },
  setSmallBagCountry(state, data) {
    state.smallBagCountry = data
  },
  setCityList(state, data) {
    state.cityList = data
  },
  setCountryList(state, data) {
    state.countryList = data
  }
}
