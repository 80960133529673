export const version = process.env.VUE_APP_VERSION
export const source = 4 // 请求来源：1-安卓；2-IOS；3-微信；4-web；5-wxmp；6-h5
export const isProd = process.env.NODE_ENV === 'production'
export const isOnline = ['www.kjhaoyun.com', 'kjhaoyun.com'].includes(location.hostname)
export const amapkey = isOnline ? '672c4aab17fbf5145f67c0db1b44a61d' : 'cb32d3a2065cfada82bee7f2a4e8c9cc'
export const amapStyle = isOnline ? 'amap://styles/dcedd202825797816b475861c923c35e' : 'amap://styles/d783f703fba83fde5d3ccd7621d6583b'
export let assets = 'https://static.kjhaoyun.com/kjhaoyun' // 在线静态资源路径
export const isPrerender = !!window['__PRERENDER_INJECTED'] // 是否为预渲染
export const isInSaasIframe = window.self !== window.top // 是否被 SaaS 以 iframe 引入

// 根据域名设置接口地址
export let api = '/api/'
export let wsServe = 'wss://ws-im.kjhaoyun.com'
export let iframeUrl = 'https://chat.kjhaoyun.com/'
export let kjcmsUrl = 'https://lives.kjhaoyun.com/'
export let vipUrl = 'https://vip.kjhaoyun.com'
export let wwwUrl = 'https://kjhaoyun.com'
export let saasUrl = 'https://g.kjhaoyun.com'
let envname = process.env.VUE_APP_ENV || location.hostname.split('.')[0]
switch (envname) {
  case '192': // 内网访问
  case 'localhost': // 本地环境
  case 'dev': // 开发环境
  case 'dev-www': // 开发域名
    wsServe = 'ws://dev-ws-im.kjhaoyun.com'
    iframeUrl = 'http://dev-chat.kjhaoyun.com/'
    kjcmsUrl = 'http://dev-lives.kjhaoyun.com/'
    vipUrl = 'http://dev-vip.kjhaoyun.com'
    wwwUrl = 'http://dev-www.kjhaoyun.com'
    saasUrl = 'http://dev-g.kjhaoyun.com'
    assets = 'https://test-static.kjhaoyun.com/kjhaoyun'
    break
  case 'test': // 测试环境
  case 'test-www': // 测试域名
    wsServe = 'wss://test-ws-im.kjhaoyun.com'
    iframeUrl = 'https://test-chat.kjhaoyun.com/'
    kjcmsUrl = 'https://lives.kjhaoyun.com/'
    vipUrl = 'https://test-vip.kjhaoyun.com'
    wwwUrl = 'https://test-www.kjhaoyun.com'
    saasUrl = 'https://test-g.kjhaoyun.com'
    assets = 'https://test-static.kjhaoyun.com/kjhaoyun'
    break
  case 'pre-www': // 预发布域名
    wsServe = 'wss://pre-ws-im.kjhaoyun.com'
    iframeUrl = 'https://pre-chat.kjhaoyun.com/'
    vipUrl = 'https://pre-vip.kjhaoyun.com'
    wwwUrl = 'https://pre-www.kjhaoyun.com'
    saasUrl = 'https://pre-g.kjhaoyun.com'
    assets = 'https://test-static.kjhaoyun.com/kjhaoyun'
    break
}

// 解决关闭聊天窗口的跨境问题。在跨域情况下此方式已彻底废了！
if (location.hostname.endsWith('kjhaoyun.com')) {
  document.domain = 'kjhaoyun.com'
}

// TODO：接口通用请求参数—待废弃
export const params = {
  version,
  source
}

// 兼容旧导出方式
export default {
  version,
  isProd,
  isOnline,
  online: isOnline,
  amapkey,
  amapStyle,
  assets,
  api,
  wsServe,
  iframeUrl,
  kjcmsUrl,
  vipUrl,
  saasUrl
}
