
import { Message } from 'view-design'
export default {
  setStateVal (state, payload) {
    try {
      let data = JSON.parse(sessionStorage.getItem('toolBarInfos')) || {}
      for (let key in payload) {
        data[key] = state[key] = payload[key]
      }
      sessionStorage.setItem('toolBarInfos', JSON.stringify(data))
    } catch (err) {
      Message.warning('使用有误，请检查')
    }
  },

  setDefaultData(state) {
    let data = JSON.parse(sessionStorage.getItem('toolBarInfos')) || {}
    for (let key in data) {
      state[key] = data[key]
    }
  }
}
