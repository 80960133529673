// 通用路由: 404 要放在最后
export default [ {
  path: '/login',
  component: () => import('@/views/layout'),
  children: [{
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/login')
  }, {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/login/register')
  }, {
    path: '/findPassword',
    name: 'reset-passwd',
    meta: {
      title: '找回密码'
    },
    component: () => import('@/views/login/find-password')
  }]
// }, {
//   path: '/register-events',
//   name: 'register-events',
//   meta: {
//     title: '注册'
//   },
//   component: () => import('@/views/events/register')
}, {
  path: '/*',
  redirect: '/',
  name: 'page404',
  meta: {
    title: '404-页面不存在'
  },
  component: () => import('@/views/404')
}]
