import Vue from 'vue'
// import '@/styles/iview.less'
import {
  LoadingBar,
  Message,
  Modal,
  Notice,
  Spin,
  Button,
  Icon,
  BackTop,
  Checkbox,
  Col,
  Card,
  Carousel,
  CarouselItem,
  DatePicker,
  TimePicker,
  Badge,
  Cascader,
  Tag,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  AutoComplete,
  InputNumber,
  Page,
  Poptip,
  Tooltip,
  Radio,
  RadioGroup,
  Row,
  Select,
  Option,
  // OptionGroup,
  // Cascader,
  Step,
  Steps,
  Table,
  Tabs,
  TabPane,
  Rate,
  Affix,
  Progress,
  Upload,
  Collapse,
  Panel,
  Timeline,
  TimelineItem,
  CheckboxGroup,
  Menu,
  Submenu,
  MenuItem,
  Alert,
  Drawer
} from 'view-design'

Vue.component('Modal', Modal)
Vue.component('Spin', Spin)
Vue.component('Button', Button)
Vue.component('Icon', Icon)
Vue.component('BackTop', BackTop)
Vue.component('Checkbox', Checkbox)
Vue.component('ICol', Col)
Vue.component('Card', Card)
Vue.component('Carousel', Carousel)
Vue.component('CarouselItem', CarouselItem)
Vue.component('Cascader', Cascader)
Vue.component('Tag', Tag)
Vue.component('DatePicker', DatePicker)
Vue.component('TimePicker', TimePicker)
Vue.component('Badge', Badge)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownItem', DropdownItem)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('Form', Form)
Vue.component('FormItem', FormItem)
Vue.component('Icon', Icon)
Vue.component('Input', Input)
Vue.component('AutoComplete', AutoComplete)
Vue.component('InputNumber', InputNumber)
Vue.component('Page', Page)
Vue.component('Poptip', Poptip)
Vue.component('Tooltip', Tooltip)
Vue.component('Radio', Radio)
Vue.component('RadioGroup', RadioGroup)
Vue.component('Row', Row)
Vue.component('Select', Select)
Vue.component('Option', Option)
// Vue.component('OptionGroup', OptionGroup)
// Vue.component('Cascader', Cascader)
Vue.component('Step', Step)
Vue.component('Steps', Steps)
Vue.component('Table', Table)
Vue.component('Tabs', Tabs)
Vue.component('TabPane', TabPane)
Vue.component('Rate', Rate)
Vue.component('Affix', Affix)
Vue.component('Progress', Progress)
Vue.component('Upload', Upload)
Vue.component('Collapse', Collapse)
Vue.component('Panel', Panel)
Vue.component('Timeline', Timeline)
Vue.component('TimelineItem', TimelineItem)
Vue.component('CheckboxGroup', CheckboxGroup)
Vue.component('Menu', Menu)
Vue.component('Submenu', Submenu)
Vue.component('MenuItem', MenuItem)
Vue.component('Alert', Alert)
Vue.component('Drawer', Drawer)

// Message.config({ top: 80 })
LoadingBar.config({ color: '#19be6b' })
Vue.prototype.$Loading = LoadingBar
Vue.prototype.$Message = Message
Vue.prototype.$Modal = Modal
Vue.prototype.$Notice = Notice
Vue.prototype.$Spin = Spin
