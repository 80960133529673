// 全局状态模块
import mutations from './mutations'
import actions from './actions'

const state = {
  channelsSlideList: [], // 方案收藏列表-侧边栏
  schemeSlideList: [] // 线路收藏列表-侧边栏
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
