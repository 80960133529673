import wj from '@/plugins/wjtools'
import * as api from '@/api'

// 登陆
export const login = ({ commit }, payload) => {
  commit('setToken', payload.token)
  commit('setUser', payload.user)
  wj.session('user', {
    user: payload.user,
    token: payload.token,
    loginStatistical: 'active' // 登录次数标记（关闭浏览器重新进入，登录状态没有失效，不需要走登录接口）
  }, 86400)
}

// 退出登录
export const logout = ({ commit }, payload) => {
  commit('setToken', '')
  commit('setUser', {
    userName: '',
    realName: '',
    role: '',
    loginStatistical: ''
  })
  wj.session('user', '')
}

// 用户位置
export const setUserLocation = ({ commit }, data) => {
  commit('setUserLocation', data)
}

// 获取用户信息
export const getUserInfo = async ({ commit }, _this) => {
  let res = await api.getMyAccount()
  if (+res.code === 200) {
    let data = res.data || {}
    commit('setUserInfo', data)
  } else {
    _this.$Message.error(res.msg || '请求失败')
  }
}

// 获取用户520活动状态
export const get520ActiveStatus = async ({ commit }) => {
  let res = await api.get520ActiveStatus()
  if (+res.code === 200) {
    let data = {
      isUserFor520: res.data.userFor520 || false,
      showActiveModal: res.data.activeDialog || false
    }
    commit('set520ActiveStatus', data)
  } else {
    this.$Message.error(res.msg || '请求失败')
  }
}

// 获取用户618活动状态
// export const get618ActiveStatus = async ({ commit }, _this) => {
//   let res = await api.getMyAccount()
//   if (+res.code === 200) {
//     let data = {
//       isUserFor618: +res.data.userType === 2 || false,
//       showActiveModal: res.data.activeDialog || false
//     }
//     commit('set618ActiveStatus', data)
//     if (data.isUserFor618 && sessionStorage.getItem('toActvie618')) {
//       _this.$router.push('/events/active618')
//       sessionStorage.removeItem('toActvie618')
//     }
//   } else {
//     _this.$Message.error(res.msg || '请求失败')
//   }
// }

// 用户引导页面查询
export const getUserGuideInfo = async({ state, commit }, _this) => {
  let guideInfo = {
    quetePrice: true,
    palceOrder: true
  }
  const res = await api.userWebCheckProcess()
  if (+res.code === 200) {
    guideInfo = {
      quetePrice: res.data.offerProcess,
      palceOrder: res.data.orderProcess
    }
    // guideInfo = {
    //   quetePrice: false,
    //   palceOrder: false
    // }
  } else {
    _this.$Message.error(res.msg || '请求失败')
  }
  commit('setUserGuideInfo', guideInfo)
  localStorage.setItem('userGuideInfo', JSON.stringify(guideInfo))
}

// 从缓存获取 - 用户引导页面查询
export const getUserGuideInfoInLocalStorage = () => {
  let guideInfo = (localStorage.getItem('userGuideInfo') && JSON.parse(localStorage.getItem('userGuideInfo'))) || {
    quetePrice: true,
    palceOrder: true
  }
  return guideInfo
}

// 用户引导页面 - 设置
export const userWebUpdateProcess = async({ state, commit }, params) => {
  let _this = params.this
  const res = await api.userWebUpdateProcess(params.params)
  if (+res.code === 200) {
    getUserGuideInfo({ state, commit })
  } else {
    _this.$Message.error(res.msg || '请求失败')
  }
}

// 获取并缓存用户 ID
export const getUserid = async(context, opts) => {
  if (!wj.isLogin() || wj.cookie('userid')) return
  const res = await api.getMyAccount()
  if (res.success) {
    let { userId } = res.data || {}
    wj.cookie('userid', userId, opts)
  }
}
