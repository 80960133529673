import dayjs from 'dayjs'
import wj from '@/plugins/wjtools'
import * as api from '@/api'
import config from '@/config'
import { Message } from 'view-design'
import istat from '@/plugins/istat'

let pageViewTimer = null

export default {
  async upServerTime({ state, commit }) {
    const res = await api.getServerTime()
    if (res && res.status === '1' && res.data.datetime) {
      commit('setServerTime', res.data.datetime)
    } else {
      commit('setServerTime', dayjs().format('YYYY-MM-DD HH:mm:ss'))
    }
  },
  async getOrders({ state, commit }) { // 待补充订单
    let data = {
      currentPage: 1,
      pageSize: 5
      // status: 'PENDING'
    }
    let res = await api.getMyOrder(data)
    if (res.success) {
      // let data = res.data
      // let show = res.data.list.length > 0
      // commit('setUnFillOrder', data)
      // commit('setShowOrder', show)
    } else {
      Message.error(res.message)
    }
  },
  async getWaitPayOrders({ dispatch, commit }) { // 待付款订单
    let data = {
      currentPage: 1,
      pageSize: 5,
      payStatus: 1
    }
    let res = await api.getMyOrder(data)
    if (res.success) {
      let data = res.data
      let show = data.list && data.list.length > 0
      commit('setWaitPayOrder', data)
      commit('setShowWaitPay', show)
      if (data.list && data.list.length <= 0) {
        dispatch('getSupplementOrders')
      }
    } else {
      Message.error(res.message)
    }
  },
  async getSupplementOrders({ dispatch, commit }) { // 待补款款订单
    let data = {
      currentPage: 1,
      pageSize: 5,
      payStatus: 1
    }
    let res = await api.getMyOrder(data)
    if (res.success) {
      let data = res.data
      let show = res.data.list.length > 0
      commit('setWaitPayOrder', data)
      commit('setShowWaitPay', show)
      if (res.data.list.length <= 0) {
        dispatch('getOrders')
      }
    } else {
      Message.error(res.message)
    }
  },
  // 建立websocket连结
  async websocketConnect({ dispatch, state, commit }) {
    let heartbeatTime = 20000
    try {
      let res = await api.getNotifyServeTime()
      if (res.code === '200' && res.data.interval) {
        heartbeatTime = res.data.interval * 1000
      }
    } catch (error) {
      console.log(error)
    }
    const token = wj.cookie('token')
    // TODO 配置${config.socket}
    const ws = new WebSocket(`${config.wsServe}/im/notify?token=${token}&&userType=2&sys=KGJ`)
    let timeInterval
    ws.onopen = function (event) {
      // console.log('ws open')
      ws.isconnected = true
      timeInterval = setInterval(() => {
        ws.send(JSON.stringify({
          'messageId': new Date().getTime() + '' + Math.random(),
          'signal': 'PING',
          'version': 1
        }))
      }, heartbeatTime)
    }
    ws.onmessage = e => {
      // console.log('收到新消息：' + e.data)
      let res = JSON.parse(e.data)
      // 消息来了之后处理模块  ToDO 这里处理消息模块通知
      if (res.signal && res.signal === 'NEW_MSG' && !state.showIframe) {
        commit('setNewMessageIM', 1)
        let list = wj.util.cloneObject(state.notifyList)
        list.map((item, index) => {
          if (item.notifyType === 'im' && res.payload) {
            list[index].content = res.payload.text.content.searchableContent
            list[index].gmtCreate = wj.util.formatDate(new Date(res.payload.text.timestamp), 'yyyy-MM-dd hh:mm:ss')
            // 后台定义-1表示消息未读
            list[index].count = -1
          }
        })
        commit('setNotifyList', list)
      }
      // 其他通知要更新两个list  还有右侧的通知  还有左侧菜单的数量
      if (res.signal === 'MESSAGE') {
        commit('setNewMessage', 1)
        commit('setUnreadCount', state.noReadCount + 1)
        let messageObj = res.payload
        let newNL = updateNotifyList(messageObj)
        let newNLD = updateNotifyDetailList(messageObj)
        commit('setNotifyList', newNL)
        commit('setNotifyDetailList', newNLD)
      }

      // let count = +res.data.count || 0
      // if (res && res.status === '1' && count > 0) {
      //   commit('setMsgtip', count + state.msgtipCount)
      // }
    }
    ws.onclose = function (event) {
      ws.close()
      clearInterval(timeInterval)
      dispatch('websocketReconnect')
    }
    ws.onerror = function (event) {
      console.log('connect error', event)
    }
    // 更新通知列表
    function updateNotifyList(obj) {
      let list = wj.util.cloneObject(state.notifyList)
      list.map((item, index) => {
        if (item.notifyType === obj.notifyType) {
          list[index].count += 1
          list[index].gmtCreate = wj.util.formatDate(new Date(obj.time), 'yyyy-MM-dd hh:mm:ss')
          list[index].content = obj.content
          list[index].event = obj.eventName
        }
      })
      return list
    }
    // 更新通知详情列表
    function updateNotifyDetailList(obj) {
      let list = wj.util.cloneObject(state.notifyDetailList)
      let notifyObj = {
        content: obj.content,
        event: obj.eventName,
        gmtCreate: wj.util.formatDate(new Date(obj.time), 'yyyy-MM-dd hh:mm:ss'),
        id: obj.id,
        status: 0,
        toUrl: obj.toUrl || '',
        notifyType: obj.notifyType
      }
      list.unshift(notifyObj)
      list = list.splice(0, 6)
      return list
    }
  },
  // websocket重连
  websocketReconnect({ dispatch }) {
    let reconnectCount = sessionStorage.getItem('reconnectCount') || 0
    if (reconnectCount < 4) {
      reconnectCount++
      sessionStorage.setItem('reconnectCount', reconnectCount)
    } else {
      sessionStorage.removeItem('reconnectCount')
      return
    }
    let timer = setTimeout(() => {
      dispatch('websocketConnect')
      clearTimeout(timer)
      timer = null
    }, 3000)
  },
  // 消息中心的方法-更新左侧菜单的未读数
  async getUnreadCount({ commit }) {
    let res = await api.getUnreadCount()
    if (res.success) {
      commit('setUnreadCount', res.data)
    } else {
      Message.error(res.message)
    }
  },
  // 打开IM系统
  openIm({ state, commit }) {
    let token = wj.cookie('token')
    commit('setToken', token)
    commit('setShowIframe', true)
    commit('setNewMessageIM', 0) // 清除im新消息提醒
    window.closeSdk()
    localStorage.removeItem('hasNewMessage')
    api.collectOpenSessionNum()
  },
  // 获取消息中心的列表
  async getInfoList({ commit }) {
    let res = await api.getAllMessageList()
    if (!res.success) {
      Message.error(res.message || '请求失败')
      return
    }
    res.data.map(item => {
      if (item.notifyType === 'im' && item.content) {
        let content = JSON.parse(item.content) || {}
        item.content = (content.text && content.text.content && content.text.content.searchableContent) || ''
      }
    })
    commit('setNotifyList', res.data || [])
  },
  // 下单前置判断条件
  async commonPlaceOrder({ state, commit }, params) {
    commit('setCanPlaceOrder', false)
    // 不做账期的判断
    // if (!params.shopId && state.billInfo.billStatus !== 0) { // 检验账期是否可以下单 非自营不判断账期
    //   commit('setShowBillTips', true)
    //   return
    // }

    // 需要验证的逻辑后置一下
    const res1 = await api.getUserVerifyInfo()
    if (+res1.code === 200) {
      let data = res1.data || {}
      commit('setVerifyInfo', data)
    } else {
      Message.error.error(res1.msg || '请求失败')
    }
    // 未进行法大大认证的
    if (!state.verifyInfo.fadadaSign) {
    // 判断是否下了10单
      let res2 = await api.getIsoverCount()
      if (!res2.success) {
        Message.error.error(res2.msg || '请求失败')
        return
      } else {
        if (!res2.data) {
          commit('setIsOverNum', true)
          commit('setShowVerifyTips', true)
          return
        }
      }
    }
    if (!localStorage.getItem('readPlatformTip') && !sessionStorage.getItem('readPlatformTip')) { // 平台公告
      commit('setShowPlatformTip', true)
      return
    }
    commit('setCanPlaceOrder', true)
  },
  reportPageViewCount({ commit, state }, payload) {
    // 页面曝光次数
    istat.report(payload)
    pageViewTimer = setInterval(() => {
      commit('setPageViewTimeCount', state.pageViewTimeCount + 1)
    }, 3000)
  },
  reportPageViewTime({ commit }, payload) {
    // 页面曝光时长
    istat.report(payload)
    clearInterval(pageViewTimer)
    pageViewTimer = null
    commit('setPageViewTimeCount', 0)
  },
  // 获取整柜港口基础数据
  getContainerPort({ commit }) {
    return new Promise((resolve, reject) => {
      api.getContainerPort().then(res => {
        if (res.code === '200') {
          let data = res.data || {}
          commit('setContainerPort', data)
        } else {
          Message.error(res.message)
        }
        resolve(res)
      })
    })
  },

  // 获取国际小包目的国家
  getSmallBagCountry({ commit }) {
    return new Promise((resolve, reject) => {
      api.getSmallCountry().then(res => {
        if (res.code === '200') {
          let data = res.data || []
          let list = []
          if (data.length) {
            list = data.map(m => {
              m.code = m.secondWord
              m.nameCN = m.nameCn
              m.nameEN = m.nameEn

              return m
            })
          }
          commit('setSmallBagCountry', list)
          resolve(res)
        } else {
          Message.error(res.message)
          reject(res)
        }
      })
    })
  },

  // 获取全网询价起运城市
  getCityList({ commit }) {
    return new Promise((resolve, reject) => {
      api.chineseCityList().then(res => {
        if (res.code === '200') {
          let data = res.data || []

          let keysList = []
          data.forEach(element => {
            element.key = element.initials || '其他' // 首字母
            element.value = element.cityId || '' // 真实值
            element.name = element.cityName || '' // 显示值
            element.key && /[a-z]/i.test(element.key) && keysList.push(element.key.toUpperCase())
            // if (element.value === cityId) {
            //   this.inputV = element.name
            //   this.chooseItem(element)
            // }
          })
          keysList = [...(new Set(keysList))].sort()
          let areaGroups = Array(keysList.length)
          let hostCitys = ({
            key: '热门',
            list: []
          })
          data.forEach(element => {
            let key = element.key && /[a-z]/i.test(element.key) ? element.key.toUpperCase() : '其他'
            let index = keysList.indexOf(key) >= 0 ? keysList.indexOf(key) : keysList.length
            areaGroups[index] = areaGroups[index] ? areaGroups[index] : {
              key,
              list: []
            }
            areaGroups[index].list.push({ ...element })
            // 热门城市
            element.isHotCity && hostCitys.list.push(element)
          })
          hostCitys.list.length && areaGroups.unshift(hostCitys)
          commit('setCityList', areaGroups)
          resolve(res)
        } else {
          Message.error(res.message)
          reject(res)
        }
      })
    })
  },

  // 获取全网询价目的国
  getCountryList({ commit }) {
    return new Promise((resolve, reject) => {
      api.getCountryTree({
        cityId: '249', // 目的国不变
        channelClass: 1
      }).then(res => {
        if (res.code === '200') {
          let data = res.data || []

          data.forEach(element => {
            element.value = element.code || ''
            element.children && element.children.forEach(item => {
              item.value = item.code || ''
              // if (item.value === countryCode) {
              //   this.chooseItem(item)
              // }
            })
          })
          commit('setCountryList', data)
          resolve(res)
        } else {
          Message.error(res.message)
          reject(res)
        }
      })
    })
  },
}
