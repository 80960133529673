import Vue from 'vue'
import './styles/iview.less'
import './styles/main.styl'
import stat from 'vue-stat'
import App from './app'
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router'
import filters from './filters'
import { isOnline, assets, isPrerender, isInSaasIframe } from './config'
import wj from './plugins/wjtools'
import istat from './plugins/istat'
import * as api from './api'
import './plugins/iview'
import './plugins/vxe-table'
import './plugins/validator'
import vueScrollwatch from 'vue-scrollwatch'
import checkView from 'vue-check-view'
import iconsvg from './components/iconsvg.vue'

Vue.config.productionTip = false // 关闭启动时生成生产提示
Vue.config.ignoredElements = [/^wx-open-/]

sync(store, router)
filters(Vue) // 注册全局过滤器
Vue.use(vueScrollwatch)
Vue.use(checkView)
Vue.prototype.$wj = wj
Vue.prototype.$util = wj.util
Vue.prototype.$api = api
Vue.prototype.$assets = assets
Vue.prototype.$isInSaasIframe = isInSaasIframe
Vue.prototype.$bus = new Vue()
Vue.component('iconsvg', iconsvg)

// 初始化 istat 统计
Vue.use(istat, {
  // url: 'https://log.kjhaoyun.com/log.gif',
  // source: '1', // 1-web端；2-合伙人web端；3-小程序
  debug: !isOnline
})

// 初始化百度统计
Vue.prototype.$stat = stat // 百度统计实例
Vue.use(stat, {
  // debug: !isOnline,
  siteId: 'd03b6f1ab006db079f78d0264a775545',
  autoPageview: false
})

new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    // 触发预渲染快照
    if (isPrerender) {
      this.$Loading.finish()
      document.dispatchEvent(new Event('render-event'))
    }
  }
}).$mount('#app')
