// import wj from '@/plugins/wjtools'
import * as api from '@/api'
import { Message } from 'view-design'
import { debounce } from 'lodash'

export default {
  // 获取侧边栏对比列表
  getSlideList: debounce(async function({ commit }, params) {
    const res = await api.comparedSchemeSildeList(params)
    const data = res.data || []
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
    if (params.comparedType === 1) {
      commit('setStateVal', {
        channelsSlideList: data
      })
    } else {
      commit('setStateVal', {
        schemeSlideList: data
      })
    }
  }, 300),
  // 获取方案库对比列表
  getChannelsList: debounce(async function({ commit }) {
    const res = await api.comparedChannelsList()
    const data = res.data || []
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
    commit('setStateVal', {
      channelsList: data
    })
  }, 300),
  // 获取线路对比列表
  getSchemeList: debounce(async function({ commit }) {
    const res = await api.comparedSchemeList()
    const data = res.data || []
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
    commit('setStateVal', {
      schemeList: data
    })
  }, 300),
  // 加入对比
  async addCompare({ state, dispatch, commit }, params) {
    let list = params.comparedType === 1 ? state.channelsSlideList : state.schemeSlideList
    if (list && list.length > 5) {
      Message.warning({
        content: '对比数量上限为5条，请调整对比范围',
        duration: 3
      })
      if (params.comparedType === 1) {
        commit('setStateVal', {
          channelsSlideList: list.slice(0, 5)
        })
      } else {
        commit('setStateVal', {
          schemeSlideList: list.slice(0, 5)
        })
      }
    } else {
      const res = await api.comparedAdd(params)
      dispatch('getSlideList', {
        comparedType: params.comparedType
      })
      if (res.code + '' !== '200') {
        Message.error(res.msg || res.message || '请求失败')
      }
    }
  },
  // 排序
  async sortCompare({ dispatch }, params) {
    const res = await api.comparedSort(params)
    if (params.comparedType === 1) {
      dispatch('getChannelsList')
    } else {
      dispatch('getSchemeList')
    }
    dispatch('getSlideList', {
      comparedType: params.comparedType
    })
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
  },
  // 删除对比
  async deleteCompare({ dispatch }, params) {
    const res = await api.comparedDelete(params)
    dispatch('getSlideList', {
      comparedType: params.comparedType
    })
    if (params.comparedType === 1) {
      dispatch('getChannelsList')
    } else {
      dispatch('getSchemeList')
    }
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
  },
  // 清空对比
  async clearCompare({ dispatch }, params) {
    const res = await api.comparedClear(params)
    dispatch('getSlideList', {
      comparedType: params.deleteType
    })
    if (params.deleteType === 1) {
      dispatch('getChannelsList')
    } else {
      dispatch('getSchemeList')
    }
    if (res.code + '' !== '200') {
      Message.error(res.msg || res.message || '请求失败')
    }
  }
}
